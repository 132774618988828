import * as React from 'react';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import CompanyPhilosophy from '../components/CompanyPhilosophy/CompanyPhilosophy';
import CompanyVision from '../components/CompanyVision/CompanyVision';
import TextImageSection from '../components/TextImageSection/textImageSection';
import Layout from '../components/layout';
import Seo from '../components/seo';

const PhilosophyPage = ({ data }) => {
  return (
    <Layout>
      <TextImageSection
        subtitle="Philosophy"
        title="企業理念"
        text="弊社はホームページを制作するだけでなく、運用までを含めたトータルサービスを得意としています。新規制作、リニューアルともにご相談を承ります。どうぞお気軽にご連絡ください。"
        imageUrl="/images/haikei3.jpg"
        imageAlt="ホームページ制作"
      />
      <Breadcrumb
        crumbs={[
          { pathname: '/', crumbLabel: 'TOP' },
          { pathname: '/about', crumbLabel: '企業概要' },
          { pathname: '/philosophy', crumbLabel: '企業理念' },
        ]}
      />
      <CompanyPhilosophy
        title="食の未来をデジタル技術で切り拓く"
        description={`
        テクノロジーと食の融合によって、
        飲食業界の課題解決とイノベーションを実現し、
        人々の食体験の質を高めていく。
        `}
      />
      <CompanyVision
        title="日本一の飲食店デジタル支援企業になる"
        description={`
        弊社は今年新たに創業した飲食店デジタル支援企業です。

        ホームページ制作やECサイト構築支援、
        SNSマーケティング支援などのデジタルプロモーションサービスを提供し、
        飲食店のデジタル化を強力にバックアップしています。

        さらに今後は、デジタルプロモーション以外の領域へも事業を広げる予定です。
        クラウド環境の整備、キャッシュレス決済の導入支援、
        POSレジの導入、注文タブレットなどのデジタル支援などのサービスを展開することで、
        基盤となるデジタル環境の構築もサポートいたします。

        長期的な視野に立てば、AIやIoTなど最新テクノロジーを活用した
        業務効率化ソリューションの開発を手掛けていく考えです。
        徹底したデジタル化を実現することで、
        日本一の飲食店デジタル支援企業を目指してまいります。
        `}
      />
    </Layout>
  );
};

export default PhilosophyPage;

export const Head = () => (
  <Seo
    title="企業理念"
    description="デジタルクリエイティブ会社は「食の未来をデジタル技術で切り拓く」ことを企業理念として掲げています。"
  />
);
