// components/CompanyPhilosophy.tsx
import React from 'react';
import FormattedText from '../commons/FormattedText/FormattedText';
import * as styles from './CompanyPhilosophy.module.css';

interface CompanyPhilosophyProps {
  title: string;
  description: string;
}

const CompanyPhilosophy: React.FC<CompanyPhilosophyProps> = ({
  title,
  description,
}) => {
  return (
    <section className={styles.sectionPrimary}>
      <div className={styles.container}>
        <h2 className={styles.head}>
          <span className={styles.headSubtext}>CORPORATE PHILOSOPHY</span>
          <span className={styles.headText}>企業理念</span>
        </h2>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.description}>
          <FormattedText text={description} />
        </p>
      </div>
    </section>
  );
};

export default CompanyPhilosophy;
