// components/CompanyVision.tsx
import React from 'react';
import FormattedText from '../commons/FormattedText/FormattedText';
import * as styles from './CompanyVision.module.css';

interface CompanyVisionProps {
  title: string;
  description: string;
}

const CompanyVision: React.FC<CompanyVisionProps> = ({
  title,
  description,
}) => {
  return (
    <section className={styles.sectionPrimary}>
      <div className={styles.container}>
        <h2 className={styles.head}>
          <span className={styles.headSubtext}>VISION</span>
          <span className={styles.headText}>ビジョン</span>
        </h2>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.description}>
          <FormattedText text={description} />
        </p>
      </div>
    </section>
  );
};

export default CompanyVision;
